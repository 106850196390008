*
::after,
::before {
  box-sizing: border-box;
}

html *{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: "Arimo", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  color: #444444;
  background-color: #ffffff;
  overflow-x: hidden;
}

html,
body {
  -webkit-scroll-behavior: smooth;
  -moz-scroll-behavior: smooth;
  scroll-behavior: smooth;
}

/*Start Theme Color ================*/
:root {
  --theme-bg: #faf7f2;
}

.theme-bg {
  background: var(--theme-bg) !important;
}

:root {
  --theme-color: #ff3061;
}

.theme-color {
  color: var(--theme-color) !important;
}

:root {
  --theme-col-2: #444444;
}

.theme-col-2 {
  color: var(--theme-col-2) !important;
}

:root {
  --theme-col-3: #666666;
}

.theme-col-3 {
  color: var(--theme-col-3) !important;
}

:root {
  --theme-col-4: #000000;
}

.theme-col-4 {
  color: var(--theme-col-4) !important;
}

:root {
  --theme-col-5: #ffffff;
}

.theme-col-5 {
  color: var(--theme-col-5) !important;
}

:root {
  --theme-col-6: #15880dee;
}

.theme-col-6 {
  color: var(--theme-col-6) !important;
}

:root {
  --theme-hover-col: #ff3061;
}

.theme-hover-col:hover {
  color: var(--theme-color) !important;
}

:root {
  --btn-bg: #FE4A49;
}

.btn-bg {
  background: var(--btn-bg) !important;
}

:root {
  --btn-bg-2: #777777;
}

.btn-bg-2 {
  background: var(--btn-bg-2) !important;
}

:root {
  --theme-bg-2: #fe4a49;
}

.theme-bg-2 {
  background: var(--theme-bg-2) !important;
}

:root {
  --theme-bg-white: #ffffff;
}

.theme-bg-white {
  background: var(--theme-bg-white) !important;
}
input,
textarea,
select,
button{
  border: none!important;
}
.navlink{
  text-decoration: none;
}

input:focus,
textarea:focus,
select:focus,
button:focus,
.input-group:focus {
  border-color: var(--theme-color) !important;
  box-shadow: none !important;
}

.form-check-input:checked {
  background-color: var(--btn-bg) !important;
  border-color: var(--theme-color) !important;
}

.flex-center {
  display: flex;
  align-items: center;
}

.layout_padding {
  padding: 90px 0;
}
.layout_padding_2 {
  padding: 50px 0;
}

button.cmn_btn {
  display: inline-block;
  padding: 5px 20px;
  color: #ffffff;
  border-radius: 0px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: none;
  font-size: 17px;
  font-weight: 400;
}
button.cmn_btn:hover{
  opacity: 0.8;
  color: #ffffff;
}

a.cmn_btn {
  display: inline-block;
  padding: 10px 30px;
  background: #a6167e;
  color: #ffffff;
  border-radius: 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: 2px solid #a6167e;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 0.9;
}

a.cmn_btn:hover {
  opacity: 1;
  color: #ffffff;
}

a.cmn_btn_2 {
  display: inline-block;
  padding: 10px 30px;
  background: transparent;
  color: #ffffff;
  border-radius: 10px;
  border: 2px solid #0e8db6;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 18px;
  font-weight: 600;
  opacity: 0.9;
}
a.cmn_btn_2 img {
  width: 28px;
}
a.cmn_btn_2:hover {
  /*border: 2px solid #0e8db6;
  background: #0e8db6;*/
  color: #ffffff;
}

a.cmn_btn_3 {
  display: inline-block;
  padding: 10px 30px;
  background: #ffffff;
  color: #0e8db6;
  border-radius: 10px;
  border: 2px solid #0e8db6;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 18px;
  font-weight: 600;
  opacity: 0.9;
}
a.cmn_btn_3 img {
  width: 28px;
}
a.cmn_btn_3:hover {
  /*border: 2px solid #0e8db6;
  background: #0e8db6;*/
  color: #0e8db6;
}

.heading_container h1 {
  position: relative;
  font-family: "Great Vibes", cursive;
  font-weight: 400;
  font-size: 45px;
}
.heading_container h1:after {
  content: "";
  position: absolute;
  right: -190px;
  top: 50%;
  width: 170px;
  height: 2px;
  background: var(--theme-bg-2) !important;
  transform: translateY(0px);
}
.heading_container h2 {
  font-weight: 600;
  font-size: 45px;
}
.heading_container p {
  font-weight: 400;
  font-size: 25px;
}

.heading_container_2 h1 {
  position: relative;
  font-family: "Great Vibes", cursive;
  font-weight: 400;
  font-size: 45px;
}
.heading_container_2 h1:after {
  content: "";
  position: absolute;
  right: -100px;
  top: 50%;
  width: 90px;
  height: 2px;
  background: var(--theme-bg-2) !important;
  transform: translateY(0px);
}
.heading_container_2 h1:before {
  content: "";
  position: absolute;
  left: -100px;
  top: 50%;
  width: 90px;
  height: 2px;
  background: var(--theme-bg-2) !important;
  transform: translateY(0px);
}

.heading_container_2 h2 {
  position: relative;
  font-weight: 600;
  font-size: 56px;
}

.heading_container_2 h3 {
  position: relative;
  font-weight: 600;
  font-size: 35px;
}

.heading_container h2 span {
  color: #fc6441;
}

.heading_container_3 h1 {
  position: relative;
  font-family: "Great Vibes", cursive;
  font-weight: 400;
  font-size: 45px;
}
.heading_container_3 h1:after {
  content: "";
  position: absolute;
  right: -100px;
  top: 50%;
  width: 90px;
  height: 2px;
  background: var(--theme-bg-white) !important;
  transform: translateY(0px);
}
.heading_container_3 h1:before {
  content: "";
  position: absolute;
  left: -100px;
  top: 50%;
  width: 90px;
  height: 2px;
  background: var(--theme-bg-white) !important;
  transform: translateY(0px);
}

.heading_container_3 h2 {
  position: relative;
  font-weight: 600;
  font-size: 56px;
}

.heading_container_4 h2 {
  position: relative;
  font-weight: 600;
  font-size: 40px;
}

.heading_container_4 p {
    font-size: 20px;
}
.heading_container.heading_center,
.heading_center_2 {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  position: relative;
}

.header_section a.navbar-brand {
  display: block;
}

a {
  transition: color 0.2s ease-in-out;
  text-decoration: none;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

a:hover,
a:focus {
  color: initial;
}

.btn,
.btn:focus {
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.row-flex {
  display: flex;
  flex-wrap: wrap;
}

/*Top Sticky Header css ============================*/
.header_section {
  position: relative;
  z-index: 9999999;
}
.home-page-header.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  -webkit-animation: navbar-show 0.25s;
  animation: navbar-show 0.25s;
  background-color: #000000;
  box-shadow: 0 0.125rem 0.625rem -0.1875rem rgba(0, 0, 0, 0.1);
}
.inner-page-header.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  -webkit-animation: navbar-show 0.25s;
  animation: navbar-show 0.25s;
  background-color: #ffffff;
  box-shadow: 0 0.125rem 0.625rem -0.1875rem rgba(0, 0, 0, 0.1);
}

@-webkit-keyframes navbar-show {
  from {
    transform: translateY(-0.75rem);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes navbar-show {
  from {
    transform: translateY(-0.75rem);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.header_section .log-sign .nav-item .nav-link {
  color: #ffffff;
  font-size: 20px;
  padding-right: .8rem;
  padding-left: .8rem;
}
.header_section .navbar-toggler-icon {
  background: url(../images/nav.png) center center !important;
  background-repeat: no-repeat;
}
.hdr-search-area {
  position: relative;
  z-index: 999;
  width: 50%;
}
.hdr-search-area .input-group {
  border: 1px solid #D2D2D2;
  border-radius: 4px;
}
.hdr-search-area .input-group:focus {
  border-color: #ff3061;
}
.hdr-search-area .form-control {
  height: 3rem;
  opacity: 1;
  background: #ffffff;
  font-size: 20px;
  border: none;
  color: #666666;
}
.hdr-search-area .input-group-text {
  padding: 0.375rem 1.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  background-color: #ff3061;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}
.hdr-search-area ::placeholder {
  color: #666666;
  opacity: 1; /* Firefox */
}
.hdr-search-area ::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #666666;
}

/*Hero Slider css====================================*/
.hero-section {
  position: relative;
  padding: 0;
  background: url(../images/hero-bg.png) center center no-repeat;
  background-size: cover;
  height: 100vh;
}
.hero-section:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgb(0 0 0 / 76%);
}
.hero-section:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background: url(../images/hero-ftr.png) bottom center no-repeat;
  background-size: 100%;
  z-index: 2;
}

.search-area {
  position: relative;
  z-index: 999;
}
.search-area .input-group {
  border: 1px solid #ffffff;
  border-radius: 4px;
}
.search-area .input-group:focus {
  border-color: #ff3061;
}
.search-area .form-control {
  height: 3.5rem;
  opacity: 1;
  background: transparent;
  font-size: 25px;
  border: none;
  color: #ffffff;
}
.search-area .input-group-text {
  padding: 0.375rem 1.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  background-color: #ff3061;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}
.search-area ::placeholder {
  color: white;
  opacity: 1; /* Firefox */
}
.search-area ::-ms-input-placeholder {
  /* Edge 12-18 */
  color: white;
}

/*Explore Epcot’s Food css====================================*/
.food-img {
  overflow: hidden;
  height: 100%;
}
.food-img figure {
  height: 100%;
}
.food-img img {
  transition: 0.3s;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.food-img img:hover {
  transform: scale(1.1);
}

/*Customer Say Css============================*/
.customer-say-sec {
  background: url(../images/customer-say.png) center center no-repeat;
  background-size: cover;
  position: relative;
}
.customer-say-sec:after {
  content: "";
  width: 100%;
  height: 280px;
  position: absolute;
  top: 0;
  left: 0;
  background: url(../images/customer-say-eft.png) top center no-repeat;
  background-size: 100%;
  z-index: 1;
}
.customer-say-sec:before {
  content: "";
  width: 273px;
  height: 173px;
  position: absolute;
  bottom: 0;
  right: 0;
  background: url(../images/red_chili_2.png) center center no-repeat;
  background-size: 100%;
  z-index: 1;
}
.customer-slider {
  position: relative;
  z-index: 10 !important;
}
.customer-slider p {
  font-size: 24px;
}
/*Snacks Say Css============================*/
.snacks-slider figure {
  width: 150px;
  height: 150px;
  margin: auto;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.snacks-slider figure img {
  width: 100%;
  transition: 0.3s ease-in-out;
}
.snacks-slider figure img:hover {
  transform: rotate(10deg) scale(1);
}
.snacks-slider p {
  font-size: 20px;
}
/*Content section Css==================*/
.content-sec {
  background: url(../images/secbg-2.png) center center no-repeat;
  background-size: cover;
}
.content-sec .cnt-sec p {
  font-size: 25px;
  line-height: 45px;
}
.content-sec .cnt-sec p:last-child {
  margin-bottom: 0;
}
/*Quote Css============================*/
.quote-sec {
  background: #faf7f2;
  background-size: cover;
  position: relative;
}
.quote-sec .cnt-sec p {
  font-size: 35px;
  font-family: "Homenaje", sans-serif;
  font-weight: 400;
}
/*Popular Restaurants Css============================*/
.popular-sec-top {
  position: relative;
  height: 500px;
}
.popular-sec-top:after {
  content: "";
  width: 326px;
  height: 258px;
  position: absolute;
  bottom: 0;
  right: 0;
  background: url(../images/popular-efct-2.png) bottom center no-repeat;
  background-size: 100%;
  z-index: 1;
}
.popular-sec-top:before {
  content: "";
  width: 326px;
  height: 258px;
  position: absolute;
  top: 0;
  left: 0;
  background: url(../images/popular-efct-1.png) top center no-repeat;
  background-size: 100%;
  z-index: 1;
}
.popular-cnt-area {
  position: relative;
  margin-top: -250px;
  z-index: 99;
}
.popular-cnt {
  box-shadow: 0px 0px 20px #e2edf8bf;
}
.popular-cnt figure {
  height: 236px;
  margin-bottom: 0;
  overflow: hidden;
}
.popular-cnt figure img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.popular-cnt h3 {
  font-size: 35px;
}
.popular-cnt p {
  font-size: 25px;
}

/* Login section================================*/
.login{
  height: 100vh;
}
.login figure{
  height: 100vh;
}
.login figure img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
p.login-with{
  font-size: 15px;
  position: relative;
}
p.login-with:after{
  content: "";
  position: absolute;
  right: 0px;
  top: 50%;
  width: 85px;
  height: 1px;
  background: #D2D2D2;
  transform: translateY(0px);
}
p.login-with:before{
  content: "";
  position: absolute;
  left: 0px;
  top: 50%;
  width: 85px;
  height: 1px;
  background: #D2D2D2;
  transform: translateY(0px);
}
.log-social-link ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.log-social-link ul li {
  width: 43px;
  height: 43px;
  border-radius: 50px;
  padding: 10px;
  box-sizing: border-box;
  background: #ffffff;
  margin: 0 10px;
  display: inline-block;
}
.log-social-link ul li a img{
  width: 100%;
}
.log-social-link ul li a img:hover{
  opacity: 0.8;
}
p.signup{
  font-size: 15px;
}
/* Navigation section================================*/
.navigation-section {
  padding: 10px 0px;
  background: #F1F2F2;
  border: 1px solid #F1F2F2;
}
.navigation-section a {
  font-size: 18px;
  line-height: 18px;
  color: #666666;
  font-weight: 400;
}
.para h4{
  font-size: 30px;
  font-weight: 600;
}
.para p{
  font-size: 25px;
  font-weight: 400;
}
.para ul{
  margin: 0;
  padding: 0;
}
.para ul li{
  list-style: none;
  display: flex;
}
.para ul li{
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 12px;
}
.para ul li i{
  margin-right: 10px;
  font-size: 20px;
  margin-top: 8px;
}
.filter-bar{
  border-bottom: 1px solid #dbceb8;
}
.filter-bar p{
  font-size: 25px;
  color: var(--theme-col-4) !important;
}
.filter-bar .col-form-label{
  font-size: 25px;
  color: var(--theme-col-4) !important;
}
.filter-bar .form-select{
  font-size: 25px;
  background-color: #faf7f2;
  border: none;
  outline: none;
  width: 100%;
}
.filter-bar2 .form-select{
  font-size: 25px;
  background-color: #faf7f2;
  border: none;
  outline: none;
  width: 130px;
}
.filter-bar .form-check .form-check-input{
  border: 1px solid #BFBFBF!important;
}
.filter-bar .accordion-button:not(.collapsed) {
    color: #ff3061;
    background-color: #ffffff;
}
.filter-bar .accordion-button:not(.collapsed)::after {
    background-image: url(../images/download.svg);
    background-size: 17px;
    transform: rotate(-180deg);
}

.underLine{
 border-bottom: 2px solid #FE4A49;
 padding-bottom: 0.5rem;
 font-weight: 700;
 width: fit-content;
}


.sort-cnt figure {
  height: 236px;
  margin-bottom: 0;
  overflow: hidden;
}
.sort-cnt figure img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.sort-cnt h3 {
  font-size: 30px;
  color: var(--theme-col-4) !important;
}
.sort-cnt h4 {
  font-size: 30px;
  color: var(--theme-col-4) !important;
  position: relative;
  font-weight: 700;
}
.sort-cnt h4:after{
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 72px;
  height: 2px;
  background: var(--theme-bg-2) !important;
}
.sort-cnt p {
  font-size: 20px;
}
.sort-cnt figure{
  position: relative;
}
span.ratting{
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: block;
  background: #24963F;
  padding: 4px 8px;
  color: #ffffff;
  font-size: 13px;
  border-radius: 3px;
}
.sort-details figure {
  height: 600px;
  margin-bottom: 0;
  overflow: hidden;
}
.sort-details figure img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
span.ratting-dtls{
  display: inline-block;
  background: #24963F;
  padding: 5px 10px;
  color: #ffffff;
  font-size: 13px;
  border-radius: 5px;
}
figure.map {
  height: 300px;
  margin-bottom: 0;
  overflow: hidden;
  margin-top: 3vh;
}
figure.map img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.filter-btn a{
  border: 1px solid var(--theme-col-4) !important;
  color: var(--theme-col-4) !important;
  font-size: 20px;
  text-align: center;
  line-height: 40px;
  border-radius: 30px;
  width: 130px;
  display: block;
  margin-right: 15px;
}
.filter-btn a:hover, .filter-btn a.active{
  border: 1px solid var(--theme-color) !important;
  color: var(--theme-color) !important;;
}
.filter-sec .nav-tabs {
    border-bottom: 2px solid #BBBBBB;
}
.filter-sec .accordion-item {
    background-color: transparent;
    border: none;
}
.filter-sec .accordion-body {
    padding: 1rem 1.25rem;
    background: var(--theme-bg-white) !important;
}
.filter-sec .accordion-body p{
  font-size: 20px;
}
.filter-sec .accordion-button{
  font-size: 22px;
  font-weight: 600;
  position: relative;
}
.filter-sec .accordion-button:not(.collapsed):before{
  content: '';
  position: absolute;
  left: 23px;
  bottom: 08px;
  width: 72px;
  height: 2px;
  background: var(--theme-bg-2) !important;
}
.filter-sec .accordion-button:not(.collapsed) {
    color: var(--theme-col-4) !important;
    background-color: #ffffff;
    box-shadow: none;
}
.filter-sec .accordion-button:not(.collapsed)::after {
    background-image: url(../images/download.svg);
    background-size: 17px;
    transform: rotate(-180deg);
}
.filter-sec .nav-link{
  font-size: 25px;
  color: var(--theme-col-2) !important;
  padding: 10px 20px;
}
.filter-sec .nav-link.active {
    color: var(--theme-color) !important;
    background-color: transparent;
    border-bottom: 2px solid var(--theme-color) !important;
}
.food-dtls-box figure {
    height: 185px;
    margin-bottom: 0;
    overflow: hidden;
}
.food-dtls-box figure img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
.food-dtls-box h4{
  color: var(--theme-col-4) !important;
  font-size: 25px;
}
.food-dtls-box p{
  color: var(--theme-col-3) !important;
  font-size: 20px;
}
.food-dtls-box .border-btm{
  border-bottom: 1px solid #F1F2F2;
}
.food-dtls-box .border-btm:last-child {
  border-bottom: none;
}
.reviews-box figure{
  width: 45px!important;
  height: 45px!important;
  overflow: hidden;
  border-radius: 50%;
}
.reviews-box figure img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.reviews-box-cnt p{
  font-size: 22px;
  color: var(--theme-col-4) !important;
}
.reviews-box-cnt span{
  font-size: 18px;
  color: var(--theme-col-2) !important;
}
.reviews-box-cnt small{
  font-size: 20px;
  color: #A2A2A2;
}
.reviews-box-cnt ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.reviews-box-cnt ul li {
    display: inline-block;
    margin: 0;
    margin-right: 2px;
    font-size: 16px;
    color: #ff9800;
}
.reviews-box-cnt {
  border-bottom: 1px solid #F1F2F2;
}
.reviews-box:last-child .reviews-box-cnt{
  border: none;
}
.abt-cnt p{
  font-size: 25px;
}
.abt-img figure {
    height: 430px;
    margin-bottom: 0;
    overflow: hidden;
}
.abt-img figure img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.ask-question .accordion-button {
  font-size: 24px;
  font-weight: 600;
  position: relative;
}
.ask-question .accordion-button:not(.collapsed) {
    color: var(--theme-col-4) !important;
    background-color: #ffffff;
    box-shadow: none;
}
.ask-question .accordion-button:not(.collapsed)::after {
    background-image: url(../images/download.svg);
    background-size: 17px;
    transform: rotate(-180deg);
}
.ask-question .accordion-item {
    background-color: #fff;
    border: none;
}
.ask-question .accordion-body {
    font-size: 20px;
}
.blog-list{
  border-bottom: 4px solid var(--theme-color) !important;
}
.blog-box figure {
  height: 236px;
  margin-bottom: 0;
  overflow: hidden;
  position: relative;
}
.blog-box figure img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: 0.3s;
}
.blog-box figure img:hover{
  transform: scale(1.1);
}
.blog-box h3 {
  font-size: 26px;
  color: var(--theme-col-4) !important;
}
.blog-box span {
  font-size: 18px;
  color: var(--theme-col-3) !important;
  position: relative;
}
.blog-box p {
  font-size: 20px;
  color: var(--theme-col-2) !important;
}
.blog-box a {
  font-size: 22px;
  color: var(--theme-color) !important;
  text-decoration: underline;
}
.blog-box a:hover{
  text-decoration: none;
}
.blog-box figure span.date{
  width: 75px;
  height: 70px;
  background: var(--theme-bg-2) !important;
  padding: 5px;
  color: var(--theme-bg-white) !important;
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.blog-details-box h3 {
  font-size: 30px!important;
  color: var(--theme-col-4) !important;
}
.blog-details-box p:last-child{
  margin-bottom: 0!important;
}
.blog-details-box figure {
  height: 300px;
}
.blog-sidebar figure{
  width: 100%!important;
  height: 90px!important;
}
.blog-sidebar figure img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blog-sidebar .blog-cnt span{
  font-size: 17px;
  color: var(--theme-color) !important;
}
.blog-sidebar .blog-cnt p{
  font-size: 20px;
  color: var(--theme-col-4) !important;
  font-weight: 600;
}
.food-details figure {
  height: 400px;
  margin-bottom: 0;
  overflow: hidden;
}
.food-details figure img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.food-btn a{
  color: var(--theme-col-2) !important;
  font-size: 22px;
  text-align: center;
  line-height: 40px;
  border-radius: 5px;
  width: 172px;
  display: block;
  margin: 3px 3px;
}
.food-btn a:first-child{
  margin-left: 0;
}
:root {
  --btn-bg1: #74F7FF;
}
.btn-bg1 {
  background: var(--btn-bg1) !important;
}
:root {
  --btn-bg2: #FF7575;
}
.btn-bg2 {
  background: var(--btn-bg2) !important;
}
:root {
  --btn-bg3: #FFC671;
}
.btn-bg3 {
  background: var(--btn-bg3) !important;
}
:root {
  --btn-bg4: #FB96F7;
}
.btn-bg4 {
  background: var(--btn-bg4) !important;
}
:root {
  --btn-bg5: #77FF74;
}
.btn-bg5 {
  background: var(--btn-bg5) !important;
}

.food-cnt h3 {
  font-size: 30px;
  color: var(--theme-col-4) !important;
}
.food-cnt p {
  font-size: 20px;
}
.food-details .food-cnt:last-child  p{
  margin-bottom: 0!important;
}
.progressbar p{
  font-size: 22px;
}
.progressbar span{
  width: 58px;
  height: 54px;
  background: #ffffff;
  font-size: 22px;
  line-height: 54px;
  border-radius: 5px;
  text-align: center;
  color: var(--theme-col-2) !important;
}
.progressbar span.active{
  background: #1CC900;
  color: var(--theme-col-5) !important;
}
.progressbar .progress{
  width: 100%;
  height: 54px;
  margin-left: 10px;
}
.progressbar .progress .progress-bar{
  height: 100%;
  background: #999999;
}
.progressbar .progress .progress-bar.active{
  background: #1CC900;
}
.photo-post figure{
  width: 100%;
  height: 200px;
  overflow: hidden;
}
.photo-post figure img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s;
}
.photo-post figure img:hover{
  transform: scale(1.1);
}
ul.user-pro .nav-item{
  display: flex;
  align-items: center;
}
ul.user-pro .nav-item span{
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
  display: block;
}
ul.user-pro .nav-item span img{
  width: 100%;
  object-fit: cover;
}
ul.user-pro .btn{
  font-size: 16px;
  color: #666666;
}
.dropdown-menu{
  margin-left: 10px;
}
.favorites-box figure {
  height: 236px;
  margin-bottom: 0;
  overflow: hidden;
  position: relative;
}
.favorites-box figure img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: 0.3s;
}
.favorites-box figure img:hover{
  transform: scale(1.1);
}
.favorites-box h3 {
  font-size: 27px;
  color: var(--theme-col-4) !important;
}
.favorites-box span {
  font-size: 25px;
  color: var(--theme-col-2) !important;
  position: relative;
}
.favorites-box p {
  font-size: 22px;
  color: var(--theme-col-3) !important;
}
.profile .prof-pic {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    margin: auto;
}
.profile .prof-pic img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.profile .Choose-btn{
  width: 200px;
  margin: auto;
}
.profile h3{
  font-size: 30px;
  color: var(--theme-col-4);
}
.profile p{
  font-size: 25px;
  color: var(--theme-col-2);
}
.prof-dtls p{
  font-size: 25px;
  color: var(--theme-col-4);
}
.prof-dtls span{
  width: 90px;
  height: 90px;
  display: block;
  background: var(--theme-bg-white);
  color: var(--theme-color);
  text-align: center;
  line-height: 90px;
  border-radius: 50%;
  margin: auto;
  font-size: 36px;
}
.cust-table td{
  font-size: 25px;
  font-weight: 600;
}
.cust-table td:first-child{
  width: 15%;
}

#scroll {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 999;
  box-shadow: 0px 2px 5px rgb(51 51 51 / 58%);
  cursor: pointer;
  width: 50px;
  height: 50px;
  background:var(--theme-color);
  text-indent: -9999px;
  display: none;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  border-radius: 60px;
}
#scroll span {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -8px;
  margin-top: -12px;
  height: 0;
  width: 0;
  border: 8px solid transparent;
  border-bottom-color: #fff;
}
#scroll:hover {
  background: var(--theme-color);
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)";
}


/* footer section================================*/
.footer_section {
  background: #000000 url(../images/ftr-text.png) center center no-repeat;
  background-size: cover;
  padding: 75px 0;
  color: #ffffff;
  position: relative;
}
.footer_section:after {
  content: "";
  width: 261px;
  height: 157px;
  position: absolute;
  bottom: 100px;
  right: 0;
  background: url(../images/rev_home3_4.png) top right no-repeat;
  background-size: 80%;
  z-index: 1;
}
.footer_section:before {
  content: "";
  width: 215px;
  height: 237px;
  position: absolute;
  top: 70px;
  left: 0;
  background: url(../images/Layer26.png) top left no-repeat;
  background-size: 80%;
  z-index: 1;
}
.footer_section .container {
  position: relative;
  z-index: 9;
}
.footer_section .ftr-nav .nav-link {
  color: #faf7f2;
  font-size: 25px;
  text-transform: uppercase;
  padding: 0.5rem 1.5rem;
}
.footer_section .social_box ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.footer_section .social_box ul li {
  margin: 0 10px;
  display: inline-block;
}
.footer_section .social_box ul li a i {
  font-size: 30px;
  color: #ffffff;
}
.ftr-copy-info p {
  color: #ffffff;
  margin: 0;
  font-size: 25px;
}
.ftr-copy-info p a {
  color: #ffffff;
}
.legal-nav .nav-link {
  color: #faf7f2;
  font-size: 25px;
  padding: 0 1rem;
  border-right: 2px solid #faf7f2;
  line-height: 20px;
}
.legal-nav .nav-item:last-child .nav-link {
  border-right: none;
}


 /* === Google Fonts === */
 @import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&display=swap');


 /* === Extra CSS === */
 
.theme-login{
  background-color: rgba(240, 202, 196, 0.905);;
} 

.search-suggetion{
  margin-top: 25px;
  background-color: white;
  border-radius: 10px;
}

.search-suggetion p{
  cursor: pointer;
  color: #000000;
  padding: 10px;
  margin-top: -10px;
}

.searchActive{
  background-color: #909090;
}

/* input button */

.file-input-container {
  position: relative;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.file-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.file-input-label {
  display: inline-block;
  background-color: transparent;
  padding: 10px 20px;
  border: 2px solid red;
  border-radius: 5px;
  color: red;
  font-weight: bold;
  cursor: pointer;
}

.file-input-label:hover {
  background-color: rgba(255, 0, 0, 0.1);
}

.Cpointer{
  cursor: pointer;
  font-size: large;
}

.footerInfo{
  font-size: 16px;
  text-align: center;
  width: 100%;
}

/* read-more-btn for infinite scroll */

.read-more-btn {
  margin-top: 15px;
  text-align: center;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* ==== Popup ==== */
.popupModal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent black */
  backdrop-filter: blur(4px); /* Small blur effect */
  display: flex;
  align-items: center;
  justify-content: center;
}

.closebtn{
 position: absolute;
 right: 0.2rem;
 top: 0.2rem;
}

.popup-main{
  display: flex;
  justify-content: center;
  margin-top: 15px;
  gap: 10px;
  padding: 0 5px;
}

.label-btn{
  background-color: lightpink;
  padding: 2px;
  width: 9rem; 
  cursor: pointer;
  border-radius: 2px;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
}

.fav-btn{
  width: 14rem !important;
}

.slider-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}


.image-gallary-button{
  position: absolute;
  top: 3rem;
  right: 3rem;
  padding: 0.5rem 3rem;
  font-size: large;
}