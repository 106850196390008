@media (max-width: 1300px) {
}

@media (max-width: 1120px) {
}

@media (max-width: 1024px) {
}

@media (max-width: 768px) {
}

@media (max-width: 576px) {
  .ftr-copy-info {
    margin-top: 10px;
  }
}

@media (min-width: 1920px) {
  .container {
    max-width: 1400px !important;
  }
}

@media (max-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 724px;
  }
  .layout_padding {
    padding: 50px 0;
  }
  .heading_container h1 {
    font-size: 36px;
  }
  .heading_container h1:after {
    right: -150px;
    width: 130px;
  }
  .heading_container h2 {
    font-size: 36px;
  }
  .heading_container_2 h1 {
    font-size: 36px;
  }
  .heading_container_2 h2 {
    font-size: 36px;
  }
  .heading_container_2 h1:after {
    right: -70px;
    width: 50px;
  }
  .heading_container_2 h1:before {
    left: -70px;
    width: 50px;
  }
  .heading_container_3 h1 {
    font-size: 36px;
  }
  .heading_container_3 h1:after {
    right: -70px;
    width: 50px;
  }
  .heading_container_3 h1:before {
    left: -70px;
    width: 50px;
  }
  .heading_container_3 h2 {
    font-size: 36px;
  }
  .customer-slider p {
    font-size: 20px;
  }
  .snacks-slider p {
    font-size: 18px;
  }
  .quote-sec .cnt-sec p {
    font-size: 30px;
  }
  .popular-sec-top:before {
    background: url(../images/popular-efct-1.png) top left no-repeat;
    background-size: 80%;
  }
  .popular-sec-top:after {
    background: url(../images/popular-efct-2.png) bottom right no-repeat;
    background-size: 80%;
  }
  .popular-cnt-area {
    margin-top: -320px;
  }
  .popular-cnt h3 {
    font-size: 28px;
  }
  .popular-cnt p {
    font-size: 20px;
  }
  .hdr-search-area {
    width: 100%;
  }
  .sort-cnt figure {
    height: 150px;
  }
  .sort-cnt h3 {
    font-size: 20px;
  }
  .sort-cnt p {
    font-size: 16px;
  }
  .filter-bar .form-select{
    font-size: 20px;
  }
  .filter-bar2 .form-select{
    font-size: 20px;
  }
  .filter-bar .col-form-label {
    font-size: 20px;
  }
  .filter-bar p {
    font-size: 20px;
  }
  .navigation-section a {
    font-size: 14px;
  }
  .sort-details figure {
    height: 400px;
  }
  figure.map {
    margin-top: 5vh;
  }
  .sort-cnt h4 {
    font-size: 26px;
  }
  .filter-btn a {
    font-size: 16px;
    text-align: center;
    line-height: 30px;
    margin: 5px;
  }
  .filter-sec .accordion-button {
    font-size: 26px;
  }
  .food-dtls-box figure {
    height: 160px;
  }
  .food-dtls-box h4 {
    font-size: 20px;
  }
  .food-dtls-box p {
    font-size: 16px;
    margin-bottom: 5px;
  }
  .reviews-box-cnt span {
    font-size: 16px;
  }
  .abt-cnt p {
    font-size: 20px;
  }
  .blog-box figure {
    height: 185px;
  }
  .blog-details-box h3 {
    font-size: 26px!important;
  }
  .blog-sidebar .blog-cnt p{
     font-size: 18px;
  }
  .favorites-box h3 {
    font-size: 24px;
  }
  .favorites-box p {
    font-size: 20px;
  }
  .favorites-box span {
    font-size: 22px;
  }

  .footer_section:before {
    background-size: 60%;
  }
  .footer_section:after {
    background-size: 60%;
  }
  .footer_section .ftr-nav .nav-link {
    font-size: 20px;
    padding: 0.5rem 1rem;
  }
  .footer_section {
    padding: 50px 0;
  }
}
@media (max-width: 575px) {
  .hdr-search-area .form-control {
    height: 2rem;
    font-size: 14px;
  }
  .header_section .log-sign .nav-item .nav-link {
    font-size: 18px;
    padding: 0.5rem 0.5rem;
  }
  .customer-slider p {
    font-size: 16px;
  }
  .customer-say-sec:before {
    background: url(../images/red_chili_2.png) center bottom no-repeat;
    background-size: 25%;
  }
  .heading_container h1 {
    font-size: 26px;
  }
  .heading_container h1:after {
    right: -70px;
    width: 60px;
  }
  .heading_container h2 {
    font-size: 26px;
  }
  .heading_container p {
    font-weight: 600;
    font-size: 16px;
  }
  .heading_container_2 h1 {
    font-size: 26px;
  }
  .heading_container_2 h1:before {
    left: -46px;
    width: 36px;
    height: 1px;
  }
  .heading_container_2 h1:after {
    right: -46px;
    width: 36px;
    height: 1px;
  }
  .heading_container_2 h2 {
    font-size: 26px;
  }
  .heading_container_3 h1 {
    font-size: 26px;
  }
  .heading_container_3 h1:before {
    left: -46px;
    width: 36px;
    height: 1px;
  }
  .heading_container_3 h1:after {
    right: -46px;
    width: 36px;
    height: 1px;
  }
  .heading_container h2 {
    font-size: 26px;
  }
  .heading_container_2 h2 {
    font-size: 26px;
  }
  .heading_container_3 h2 {
    font-size: 26px;
  }
  .heading_container_4 h2 {
    font-size: 26px;
  }
  .content-sec .cnt-sec p {
    font-size: 16px;
    line-height: normal;
  }
  .quote-sec .cnt-sec p {
    font-size: 22px;
  }
  .popular-cnt-area {
    margin-top: -340px;
  }
  .popular-sec-top:before {
    background-size: 45%;
  }
  .popular-sec-top:after {
    background-size: 45%;
  }
  .popular-cnt h3 {
    font-size: 22px;
  }
  .popular-cnt p {
    font-size: 18px;
  }
  .heading_container_4 h2 {
    font-size: 26px;
  }
  .heading_container_4 p {
    font-size: 16px;
  }
  .para h4 {
    font-size: 20px;
  }
  .para p {
    font-size: 16px;
  }
  .para ul li {
    font-size: 16px;
    margin-bottom: 8px;
  }
  .para ul li i {
    margin-right: 8px;
    font-size: 16px;
    margin-top: 5px;
  }
  .layout_padding_2 {
    padding: 30px 0;
  }
  .sort-cnt h4 {
    font-size: 20px;
  }
  figure.map {
    margin-top: 0;
  }
  .filter-btn a {
    font-size: 14px;
    text-align: center;
    line-height: 25px;
    margin-right: 5px;
    margin: 3px;
    width: 100px;
  }
  .filter-sec .nav-link {
    font-size: 16px;
    padding: 5px 10px;
  }
  .filter-btn{
    border-bottom: 1px solid #f9e2e2;
    padding-bottom: 10px;
  }
  .filter-bar .form-select {
    font-size: 20px;
    width: 100px;
  }
  .food-dtls-box h4 {
    font-size: 18px;
  }
  .filter-sec .accordion-button {
    font-size: 16px;
    padding: 0.5rem;
  }
  .filter-sec .accordion-button:not(.collapsed):before {
    left: 8px;
    bottom: 5px;
    width: 50px;
  }
  .filter-sec .accordion-body{
    padding: 1rem;
  }
  .reviews-box-cnt ul li {
    margin-right: 0px;
    font-size: 14px;
  }
  .reviews-box-cnt small {
    font-size: 14px;
  }
  .abt-cnt p {
    font-size: 16px;
  }
  .abt-img figure {
    height: 250px;
  }
  .ask-question .accordion-button {
    font-size: 16px;
  }
  .ask-question .accordion-body {
    font-size: 16px;
    padding: 1rem;
  }
  .ask-question .accordion-button{
    padding: 0.5rem;
  }
  .blog-box h3 {
    font-size: 22px;
  }
  .blog-box p {
    font-size: 18px;
  }
  .blog-box a {
    font-size: 18px;
  }
  .blog-details-box h3 {
  font-size: 22px!important;
  }
  .blog-sidebar .blog-cnt p {
      font-size: 16px;
  }
  .blog-sidebar .blog-cnt span{
    font-size: 14px;
  }
  .progressbar p {
    font-size: 16px;
  }
  .progressbar span {
    width: 50px;
    height: 46px;
    font-size: 18px;
    line-height: 46px;
  }
  .progressbar .progress {
    height: 46px;
  }
  .food-cnt h3 {
    font-size: 22px;
  }
  .food-cnt p {
    font-size: 16px;
  }
  .food-btn a {
    font-size: 16px;
    line-height: 35px;
    width: 108px;
    margin: 3px 3px;
  }
  .filter-sec .accordion-body p {
    font-size: 16px;
  }
  .favorites-box h3 {
    font-size: 22px;
  }
  .favorites-box p {
    font-size: 18px;
  }
  .favorites-box span {
    font-size: 20px;
  }


  .footer_section .ftr-nav .nav-link {
    font-size: 14px;
    padding: 0.5rem 0.6rem;
  }
  .footer_section .social_box ul li a i {
    font-size: 26px;
  }
  .footer_section .social_box ul li {
    margin: 0 5px;
  }
  .footer_section:before {
    content: inherit;
  }
  .footer_section:after {
    content: inherit;
  }
  .ftr-copy-info p {
    font-size: 14px;
  }
  .legal-nav .nav-link {
    font-size: 14px;
  }
  .cust-table td{
    font-size: 18px;
    font-weight: 600;
  }
  .cust-table td:first-child{
    width: 25%;
  }
  .profile .prof-pic {
    width: 150px;
    height: 150px;
  }
  .profile h3 {
    font-size: 24px;
  }
  .profile p {
    font-size: 18px;
  }
}
@media (max-width: 991px) {
  .header_section .log-sign .nav-item .nav-link{
    color: var(--theme-col-2);
  }
  .navbar {
    background: var(--theme-col-1);
  }
  .homenavbar{
    background: var(--theme-col-4);
    color: whitesmoke;
  }
  .homenavbar2{
    background: var(--theme-col-2);
  }
  .user-pro{
    margin-top: 10px;
  }
  ul.user-pro .nav-item {
    align-items: start;
  }
  ul.user-pro .btn{
    margin-top: 5px;
  }
}
